import * as React from 'react'

export const IconMessageSent = (props) => {
  return (
    <svg width={128} height={128} viewBox='0 0 128 128' fill='none' {...props}>
      <circle cx={64} cy={64} r={64} fill='#F7DF1E' />
      <path
        d='M103.969 44.104a1.001 1.001 0 00-.016-.11v-.037a1.374 1.374 0 00-.24-.435c-.009-.01-.009-.024-.017-.034-.008-.01-.048-.03-.067-.05a1.38 1.38 0 00-.338-.261 1.366 1.366 0 00-.658-.177H41.931a1.34 1.34 0 00-.44.079 1.308 1.308 0 00-.127.058 1.195 1.195 0 00-.375.252 1.403 1.403 0 00-.27.356c-.014.026-.035.047-.047.075-.029.074-.05.15-.065.228-.009.032-.028.058-.033.091l-6.898 38.629a.764.764 0 000 .08 1.38 1.38 0 00.018.437c0 .035 0 .07.015.103 0 .014 0 .028.007.042.053.158.134.304.24.433.008.01.01.024.016.033.007.01.049.03.068.051.098.104.212.192.338.26a1.363 1.363 0 00.655.18h60.702c.153 0 .305-.028.45-.08.047-.019.093-.041.137-.066.09-.04.176-.09.256-.147.043-.038.084-.078.122-.12.065-.063.123-.132.174-.206.034-.053.064-.108.091-.164.02-.043.051-.078.066-.122.012-.045.022-.091.028-.138a.821.821 0 00.036-.098l6.898-38.628a1.067 1.067 0 000-.08 1.35 1.35 0 00-.024-.434zm-5.695 1.655l-32.68 22.998-20.577-22.998h53.257zm-55.482 1.656l15.556 17.382-21.364 15.139 5.808-32.521zm-3.424 34.213l20.831-14.761 4.154 4.642a1.38 1.38 0 001.823.207l7.74-5.445L91.98 81.628H39.368zm55.43-1.226l-18.55-15.769 24.438-17.196-5.888 32.965zM25.38 47.139h11.036a1.38 1.38 0 000-2.76H25.38a1.38 1.38 0 000 2.76zM35.037 54.037a1.38 1.38 0 00-1.38-1.38H25.38a1.38 1.38 0 000 2.76h8.277a1.38 1.38 0 001.38-1.38zM30.898 60.934H25.38a1.38 1.38 0 000 2.76h5.518a1.38 1.38 0 000-2.76zM28.139 69.212h-2.76a1.38 1.38 0 000 2.76h2.76a1.38 1.38 0 000-2.76z'
        fill='#000'
      />
    </svg>
  )
}
